import React, { useState, useEffect } from "react";
import { Box, Button, Heading, Text, useToast, Table, Thead, Tbody, Tr, Th, Td,Flex } from "@chakra-ui/react";
import axios from "axios";

const Subscription = ({ supplier }) => {
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const toast = useToast();
  const userid = localStorage.getItem("userid");

  // Function to create a checkout session
  const createCheckoutSession = async () => {
    try {
      const payment_response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/get-subscription-amount`, {
        userid: userid,
        supplier
      });

      const subscription_amount = payment_response.data.subscription_amount;

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/create-checkout-session`, {
        amount: subscription_amount,
        userid: userid,
        supplier
      });

      setCheckoutUrl(response.data.checkoutUrl);
      toast({
        title: "Checkout session created.",
        description: "You will be redirected to the payment page.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Redirect to the checkout page
      window.location.href = response.data.checkoutUrl;
    } catch (error) {
      toast({
        title: "Error creating checkout session.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to get payment history
  const fetchPaymentHistory = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/get-payment-history`, {
        userid: userid,
        supplier
      });

      setPaymentHistory(response.data.data || []);
    } catch (error) {
      toast({
        title: "Error fetching payment history.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to get invoice history
  const fetchInvoiceHistory = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/get-invoice-history`, {
        userid: userid,
        supplier
      });

      setInvoiceHistory(response.data.data.invoices || []);
    } catch (error) {
      toast({
        title: "Error fetching invoice history.",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
    fetchInvoiceHistory();
  }, [supplier]);

  const generate_invoice = async () => {
    //setDownloadingInvoices((prev) => ({ ...prev, [invoice_number]: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/myProfile/generate-invoice`,
        { supplier },
        {
          responseType: "blob",
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${supplier}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    } finally {
      //setDownloadingInvoices((prev) => ({ ...prev, [invoice_number]: false }));
    }
  };

  return (
    <Box p={5}>
      <Heading as="h2" size="lg" mb={4}>
        {supplier} 
      </Heading>

      <Flex direction="row" justify="space-between" align="flex-start" gap={4}>
      <Button colorScheme="teal" onClick={createCheckoutSession} mb={4}>
        Make Payment
      </Button>

      {userid==1 && <Button colorScheme="teal" onClick={generate_invoice} mb={4}>
        Generate Invoice
      </Button>}
      </Flex>

      <Heading as="h3" size="md" mt={6} mb={4}>
        Payment History
      </Heading>
      {paymentHistory.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentHistory.map((payment) => (
              <Tr key={payment.payment_id}>
                <Td>{new Date(payment.payment_date).toLocaleDateString()}</Td>
                <Td>{(parseFloat(payment.payment_amount) || 0).toFixed(2)} ZAR</Td>
                <Td>{payment.payment_status ? "Paid" : "Unpaid"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No payment history available.</Text>
      )}

      <Heading as="h3" size="md" mt={6} mb={4}>
        Invoice History
      </Heading>
      {invoiceHistory.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Method</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoiceHistory.map((invoice) => {
              // Parse the payment method JSON string back into an object
              const paymentMethod = typeof invoice.method === "string" ? JSON.parse(invoice.method) : invoice.method;

              // Extract relevant information from the payment method
              const paymentType = paymentMethod.type;
              const cardDetails = paymentMethod.card ? `${paymentMethod.card.scheme.toUpperCase()} ${paymentMethod.card.maskedCard} (Exp: ${paymentMethod.card.expiryMonth}/${paymentMethod.card.expiryYear})` : "N/A";

              return (
                <Tr key={invoice.id}>
                  <Td>{new Date(invoice.date).toLocaleDateString()}</Td>
                  <Td>{(parseFloat(invoice.amount) || 0).toFixed(2)} ZAR</Td>
                  <Td>{invoice.status}</Td>
                  <Td>{paymentType === "card" ? cardDetails : paymentType}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Text>No invoice history available.</Text>
      )}
    </Box>
  );
};

export default Subscription;
