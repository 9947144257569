import React, { useEffect, useState } from "react";
import { Box, Spinner, Heading, useTheme } from "@chakra-ui/react";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart2 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const [chartDiv, setChartDiv] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartHeight, setChartHeight] = useState("300px"); // Default height

  useEffect(() => {
    let root;

    const initChart = async () => {
      if (chartDiv) {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/rep_sales/chart2`,
            {
              supplier,
              retailer,
              userid: localStorage.getItem("userid"),
            }
          );

          const data = res.data.data.map((item) => ({
            region: item.region,
            value: Number(item.value),
          }));

          // Calculate dynamic chart height
          const itemHeight = 50; // Height per bar (adjust as needed)
          const minChartHeight = 300; // Minimum chart height in pixels
          const totalHeight = Math.max(data.length * itemHeight, minChartHeight);
          setChartHeight(`${totalHeight}px`);

          root = am5.Root.new(chartDiv);
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              layout: root.verticalLayout,
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              paddingLeft: 150, // Increase left padding for labels
            })
          );

          // Create axes
          const yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: "region",
              renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 20,
              }),
            })
          );

          yAxis.data.setAll(data);

          // Adjust y-axis labels
          yAxis.get("renderer").labels.template.setAll({
            fontSize: 12,
            maxWidth: 140,
            oversizedBehavior: "wrap",
            textAlign: "end",
          });

          const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          // Remove grid lines if needed
          xAxis.get("renderer").grid.template.setAll({
            visible: false,
          });

          yAxis.get("renderer").grid.template.setAll({
            visible: false,
          });

          // Create series
          const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Value",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "value",
              categoryYField: "region",
              sequencedInterpolation: true,
            })
          );

          series.data.setAll(data);

          // Add gradient to columns
          series.columns.template.setAll({
            height: am5.percent(100),
            strokeOpacity: 0,
            tooltipText: "{categoryY}: R{formattedValueX}",
          });

          // Define gradient fill
          series.columns.template.set(
            "fillGradient",
            am5.LinearGradient.new(root, {
              stops: [
                { color: am5.color("#000046") },
                { color: am5.color("#1CB5E0") },
              ],
              rotation: 0,
            })
          );

          // Use adapters to format the tooltip text
          series.columns.template.adapters.add(
            "tooltipText",
            function (text, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const category = dataItem.get("categoryY");
                const value = dataItem.get("valueX");
                const formattedValue = root.numberFormatter.format(
                  value,
                  "#,###"
                );
                return `${category}: R${formattedValue}`;
              }
              return text;
            }
          );

          series.bullets.push(function () {
            const label = am5.Label.new(root, {
              text: "",
              populateText: true,
              centerY: am5.p50,
              paddingLeft: 5,
              paddingRight: 5,
              paddingTop: 2,
              paddingBottom: 2,
            });
          
            // Format the label text
            label.adapters.add("text", function (text, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const value = dataItem.get("valueX");
                const formattedValue = root.numberFormatter.format(value, "#,###");
                return `R${formattedValue}`;
              }
              return text;
            });
          
            // Adjust label position based on bar size
            label.adapters.add("centerX", function (centerX, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const series = dataItem.component;
                const xAxis = series.get("xAxis");
                const value = dataItem.get("valueX");
                const max = xAxis.getPrivate("max");
                const percentage = value / max;
          
                if (percentage < 0.7) {
                  // For small bars, position label outside to the right
                  return am5.p0; // Align to the end
                } else {
                  // For large bars, keep label centered inside
                  return am5.p100;
                }
              }
              return centerX;
            });
          
            // Shift label position when outside the bar
            label.adapters.add("dx", function (dx, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const series = dataItem.component;
                const xAxis = series.get("xAxis");
                const value = dataItem.get("valueX");
                const max = xAxis.getPrivate("max");
                const percentage = value / max;
          
                if (percentage < 0.6) {
                  // Shift label slightly to the right when outside
                  return 20; // Adjust as needed
                } else {
                  return 0;
                }
              }
              return dx;
            });
          
            // Adjust label text color
            label.adapters.add("fill", function (fill, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const series = dataItem.component;
                const xAxis = series.get("xAxis");
                const value = dataItem.get("valueX");
                const max = xAxis.getPrivate("max");
                const percentage = value / max;
          
                if (percentage < 0.7) {
                  // For small bars, use dark text
                  return am5.color("#000000");
                } else {
                  // For large bars, use white text
                  return am5.color("#ffffff");
                }
              }
              return fill;
            });
          
            // Adjust label background opacity
            label.adapters.add("background.fillOpacity", function (fillOpacity, target) {
              const dataItem = target.dataItem;
              if (dataItem) {
                const series = dataItem.component;
                const xAxis = series.get("xAxis");
                const value = dataItem.get("valueX");
                const max = xAxis.getPrivate("max");
                const percentage = value / max;
          
                if (percentage < 0.7) {
                  // For small bars, remove background
                  return 0;
                } else {
                  // For large bars, keep background
                  return 0.5;
                }
              }
              return fillOpacity;
            });
          
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: label,
            });
          });
          



          // Make chart responsive
          chart.appear(1000, 100);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching data", error);
          setLoading(false);
        }
      }
    };

    initChart();

    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [chartDiv, supplier, retailer]);

  return (
    <Box
      height={chartHeight} // Use calculated chart height
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Heading
        size="md"
        textAlign="center"
        mb={2}
        bg={theme.colors.primary2}
        color="white"
        p={1}
      >
        by Region
      </Heading>
      <Box flex="1" position="relative">
        <div
          ref={(div) => setChartDiv(div)}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        ></div>
        {loading && (
          <Spinner
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        )}
      </Box>
    </Box>
  );
};

export default Chart2;
