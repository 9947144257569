// import.js

import React, { useState,useRef } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Spinner,
  Text,
} from "@chakra-ui/react";

const Import = ({ supplier, retailer }) => {
    const [isUploading, setIsUploading] = useState(false);
    const toast = useToast();
    const fileInputRef = useRef(null);
  
    const handleButtonClick = () => {
      if (!retailer || !supplier) {
        toast({
          title: "Missing Information",
          description: "Please select a retailer and supplier before uploading.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      fileInputRef.current.click();
    };
  
    const handleFileChange = async (event) => {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;
  
      // Reset the input value to allow uploading the same file again if needed
      event.target.value = "";
  
      // Validate file type
      if (
        selectedFile.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        toast({
          title: "Invalid File Type",
          description: "Please upload a valid .xlsx file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      await uploadFile(selectedFile);
    };
  
    const uploadFile = async (file) => {
      setIsUploading(true);
  
      const vars = {
        supplier: supplier.toLowerCase(),
        retailer: retailer.toLowerCase(),
      };
  
      const formData = new FormData();
      formData.append("xlsxFile", file);
      Object.keys(vars).forEach((key) => {
        formData.append(key, vars[key]);
      });
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/data/import-margin-report/`, {
          method: "POST",
          body: formData,
        });
  
        const data = await response.json();
  
        if (response.status === 201) {
          toast({
            title: "Upload Successful",
            description: data.message || "File uploaded successfully.",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Upload Failed",
            description: data.message || "There was an issue uploading your file.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Upload Error:", error);
        toast({
          title: "Upload Error",
          description: `An error occurred: ${error.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsUploading(false);
      }
    };
  
    return (
      <Box>
        <Button
          colorScheme="blue"
          onClick={handleButtonClick}
          leftIcon={isUploading && <Spinner size="sm" />}
          isDisabled={isUploading}
        >
          {isUploading ? "Uploading..." : "Upload File"}
        </Button>
        <input
          type="file"
          accept=".xlsx"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>
    );
  };
  
  export default Import;