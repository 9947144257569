import React, { useEffect, useState } from "react";
import { Box, Spinner, Heading, useTheme } from "@chakra-ui/react";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart1 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const [chartDiv, setChartDiv] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let root;

    const initChart = async () => {
      if (chartDiv) {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/rep_sales/chart1`,
            {
              supplier,
              retailer,
              userid: localStorage.getItem("userid"),
            }
          );

          const data = res.data.data.map((item) => ({
            category: item.site,
            value: Number(item.value),
          }));

          root = am5.Root.new(chartDiv);
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          const chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              radius: am5.percent(80),
              innerRadius: am5.percent(50),
              layout: root.horizontalLayout,
            })
          );

          // Create series
          const series = chart.series.push(
            am5percent.PieSeries.new(root, {
              name: "Series",
              valueField: "value",
              categoryField: "category",
            })
          );

          series.data.setAll(data);

          // Enable labels on slices
          series.labels.template.setAll({
            visible: true,
            text: "{category}: R{value}",
            fontSize: 12,
          });

          // Use adapters to format the value in labels
          series.labels.template.adapters.add("text", function (text, target) {
            const dataItem = target.dataItem;
            if (dataItem) {
              const category = dataItem.get("category");
              const value = dataItem.get("value");
              const formattedValue = root.numberFormatter.format(value, "#,###");
              return `${category}: R${formattedValue}`;
            }
            return text;
          });

          // Adjust label positioning if needed
          series.labels.template.adapters.add("radius", function (radius, target) {
            return target.dataItem.slice.get("radius") * 0.7;
          });

          // Adding gradients
          series.slices.template.set("strokeOpacity", 0);
          series.slices.template.set(
            "fillGradient",
            am5.RadialGradient.new(root, {
              stops: [
                { brighten: -0.8 },
                { brighten: -0.8 },
                { brighten: -0.5 },
                { brighten: 0 },
                { brighten: -0.5 },
              ],
            })
          );

          // Customize tooltips to show Rand values with formatting
          series.slices.template.set(
            "tooltipText",
            "{category}: R{value} ({valuePercentTotal}%)"
          );

          // Use adapters to format the value in tooltips
          series.slices.template.adapters.add("tooltipText", function (text, target) {
            const dataItem = target.dataItem;
            if (dataItem) {
              const category = dataItem.get("category");
              const value = dataItem.get("value");
              const percent = dataItem.get("valuePercentTotal");
              const formattedValue = root.numberFormatter.format(value, "#,###");
              const formattedPercent = root.numberFormatter.format(percent, "0.0");
              return `${category}: R${formattedValue} (${formattedPercent}%)`;
            }
            return text;
          });

          // Create legend
          const legend = chart.children.push(
            am5.Legend.new(root, {
              centerY: am5.percent(50),
              y: am5.percent(50),
              layout: root.verticalLayout,
            })
          );

          // Adjust legend item containers to reduce spacing
          legend.itemContainers.template.setAll({
            paddingTop: 2,
            paddingBottom: 2,
            marginTop: 0,
            marginBottom: 0,
          });

          // Customize legend labels
          legend.labels.template.setAll({
            text: "{category}",
            fontSize: "14px",
          });

          // Customize legend value labels using an adapter
          legend.valueLabels.template.setAll({
            text: "", // Placeholder text
            textAlign: "right",
            fontSize: "14px",
          });

          legend.valueLabels.template.adapters.add("text", (text, target) => {
            const dataItem = target.dataItem;
            if (dataItem) {
              const value = dataItem.get("value");
              const percent = dataItem.get("valuePercentTotal");
              const formattedValue = root.numberFormatter.format(value, "#,###");
              const formattedPercent = root.numberFormatter.format(percent, "0.0");
              return ` (${formattedPercent}%)`;
            }
            return text;
          });

          // Adjust legend markers
          legend.markerRectangles.template.setAll({
            width: 12,
            height: 12,
            marginRight: 5,
          });

          // Set legend data
          legend.data.setAll(series.dataItems);

          // Play initial series animation
          series.appear(1000, 100);
        } catch (error) {
          console.error("Error fetching data", error);
        } finally {
          setLoading(false);
        }
      }
    };

    initChart();

    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [chartDiv, supplier, retailer]);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Heading
        size="md"
        textAlign="center"
        mb={2}
        bg={theme.colors.primary2}
        color="white"
        p={1}
      >
        by Site Profile
      </Heading>
      <Box flex="1" position="relative">
        <div
          ref={(div) => setChartDiv(div)}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        ></div>
        {loading && (
          <Spinner
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        )}
      </Box>
    </Box>
  );
};

export default Chart1;
