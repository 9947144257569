import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, useLocation, Navigate } from "react-router-dom";
import { FaStoreAlt, FaChartLine, FaLayerGroup, FaUsers, FaSignOutAlt, FaFileImport, FaSignInAlt, FaEnvelope, FaInfoCircle, FaBook, FaStore, FaUser, FaCog } from "react-icons/fa";
import { Flex, Box, Text, Button, Image, useTheme, Link, Divider, IconButton } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import Users from "./pages/admin/users/Users";
import AddUser from "./pages/admin/users/AddUser";
import EditPageAccess from "./pages/admin/users/EditPageAccess";
import EditSupplierAccess from "./pages/admin/users/EditSupplierAccess";
import EditRetailerAccess from "./pages/admin/users/EditRetailerAccess";

import Articles from "./pages/admin/articles/Articles";
import Stores from "./pages/admin/stores/Stores";
import LoginHistory from "./pages/admin/history/LoginHistory";

import Sales from "./pages/sales/Sales";

import Imports from "./pages/admin/imports/Imports";

import Edi from "./pages/admin/edi/Edi";

import Delete from "./pages/admin/delete/Delete";

import StrikeRate from "./pages/strike/StrikeRate";

import Ranging from "./pages/ranging/Ranging";

import Margin from "./pages/margin/Margin";

import Signin from "./pages/login/Signin";
import SignupPage from "./pages/login/Signup";
import Contact from "./pages/admin/marketing/Contact";
import About from "./pages/admin/marketing/About";

import MyProfile from "./pages/myProfile/MyProfile";

import NotificationPopup from "./components/NotificationPopup";
import TrialBanner from "./components/TrialBanner";

import { useAuthentication } from "./wrappers/UseAuthentication";
import RetailerNavigation from "./components/RetailerNavigation";
import SupplierNavigation from "./components/SupplierNavigation";
import SupplierLogo from "./components/SupplierLogo";
import { useSupplierRetailer } from "./wrappers/SupplierRetailerContext";
import { usePageAccess } from "./wrappers/PageAccessContext";

const Layout = () => {
  const { isAuthenticated, signOut } = useAuthentication();
  const { retailers, user_supplier_access, loading } = useSupplierRetailer();
  const { user_page_access, loading: pageLoading, restrictedyn, subscriptionyn, trialyn, paymentReminder } = usePageAccess();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();

  const linkStyles = {
    marginRight: "20px",
    textDecoration: "none",
    color: "black",
    _hover: {
      color: "red",
      textDecoration: "none",
    },
  };

  const getLinkStyles = (path) => ({
    color: location.pathname === path ? "white" : "black",
    _hover: {
      color: theme.colors.secondary2,
    },
  });

  const [adminImage, setAdminImage] = useState("/spearicon.jpg");
  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (userid == 5 || userid == "5") {
      setAdminImage("/DAWN3.jpg");
      //setAdminImage('/spearicon.jpg');
    }
  }, []);

  useEffect(() => {
    if (paymentReminder) {
      // Use a timeout to ensure state update is captured
      setIsNotificationOpen(true);
      // const timer = setTimeout(() => {
      //   setIsNotificationOpen(true);
      // }, 100); // Short delay to ensure state is updated
      // console.log(isNotificationOpen);

      //return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [paymentReminder]);

  return (
    <Flex minHeight="100vh" overflow="hidden">
      <NotificationPopup key={paymentReminder} isOpen={isNotificationOpen} onClose={() => setIsNotificationOpen(false)} message={paymentReminder} />
      <TrialBanner trialyn={trialyn} />

      <Flex as="nav" direction="column" bgGradient="linear(to-b, primary1, primary1)" color="white" width="150px" height="100vh" position="fixed">
        <Box display="flex" alignItems="center" minHeight="111.73" bg="white">
          <Image src={adminImage} alt="Logo" objectFit="cover" />
        </Box>
        {isAuthenticated && !pageLoading && user_page_access.length > 0 && (
          <>
            {user_page_access.some((page) => page.value === "MyProfile") && (
              <IconButton
                as={NavLink}
                to="/myProfile"
                icon={<FaCog color="white" />}
                aria-label="My Profile"
                variant="ghost"
                size="md"
                fontSize="2xl"
                style={{
                  position: "fixed",
                  top: "65px",
                  right: "5px",
                  zIndex: 9999,
                  backgroundColor: `${theme.colors.primary1}`, // Optional: Background color to ensure visibility
                  padding: "5px", // Optional: Padding for better appearance
                  borderRadius: "50%", // Optional: Make it a circle for a better look
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a subtle shadow for depth
                }}
              />
            )}

            {user_supplier_access.length > 0 && <SupplierNavigation />}

            {user_page_access.some((page) => page.value === "Sales" && !restrictedyn) && (
              <Link as={NavLink} to="/sales" sx={{ ...linkStyles, ...getLinkStyles("/sales") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaStoreAlt />
                  <Text ml={2}>Sales</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "StrikeRate" && !restrictedyn) && (
              <Link as={NavLink} to="/strike" sx={{ ...linkStyles, ...getLinkStyles("/strike") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaChartLine />
                  <Text ml={2}>Strike Rate</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Ranging" && !restrictedyn) && (
              <Link as={NavLink} to="/ranging" sx={{ ...linkStyles, ...getLinkStyles("/ranging") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaLayerGroup />
                  <Text ml={2}>Ranging</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Margin" && !restrictedyn) && (
              <Link as={NavLink} to="/margin" sx={{ ...linkStyles, ...getLinkStyles("/margin") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaStoreAlt />
                  <Text ml={2}>Margin</Text>
                </Box>
              </Link>
            )}
            <Divider mb={4} />
            {user_page_access.some((page) => page.value === "Users") && (
              <Link as={NavLink} to="/users" sx={{ ...linkStyles, ...getLinkStyles("/users") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUsers />
                  <Text ml={2}>Users</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Articles") && (
              <Link as={NavLink} to="/articles" sx={{ ...linkStyles, ...getLinkStyles("/articles") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaBook />
                  <Text ml={2}>Articles</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Stores") && (
              <Link as={NavLink} to="/stores" sx={{ ...linkStyles, ...getLinkStyles("/stores") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaStore />
                  <Text ml={2}>Stores</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Imports") && (
              <Link as={NavLink} to="/imports" sx={{ ...linkStyles, ...getLinkStyles("/imports") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaFileImport />
                  <Text ml={2}>Imports</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Edi") && (
              <Link as={NavLink} to="/edi" sx={{ ...linkStyles, ...getLinkStyles("/edi") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaFileImport />
                  <Text ml={2}>Edi</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Delete") && (
              <Link as={NavLink} to="/delete" sx={{ ...linkStyles, ...getLinkStyles("/delete") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaFileImport />
                  <Text ml={2}>Delete</Text>
                </Box>
              </Link>
            )}

            {user_page_access.some((page) => page.value === "MyProfile") && (
              <Link as={NavLink} to="/myProfile" sx={{ ...linkStyles, ...getLinkStyles("/myProfile") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUser />
                  <Text ml={2}>My Profile</Text>
                </Box>
              </Link>
            )}
            <Divider mb={4} />
            {user_page_access.some((page) => page.value === "LoginHistory") && (
              <Link as={NavLink} to="/loginhistory" sx={{ ...linkStyles, ...getLinkStyles("/loginhistory") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUsers />
                  <Text ml={2}>History</Text>
                </Box>
              </Link>
            )}
            <Button leftIcon={<FaSignOutAlt />} onClick={signOut} variant="outline" bg={theme.colors.primary1} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="white" position="fixed" bottom="10px" left="10px" zIndex="1000">
              Logout
            </Button>
          </>
        )}
        {!isAuthenticated && (
          // <NavLink to="/signin" style={{ marginRight: "20px" }}>
          //   <Text>Sign In</Text>
          // </NavLink>
          <>
            <Link as={NavLink} to="/signin" sx={{ ...linkStyles, ...getLinkStyles("/signin") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                <FaSignInAlt />
                <Text ml={2}>Sign In</Text>
              </Box>
            </Link>
            <Link as={NavLink} to="/contact-us" sx={{ ...linkStyles, ...getLinkStyles("/contact-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaEnvelope />
                <Text ml={2}>Contact Us</Text>
              </Box>
            </Link>
            <Link as={NavLink} to="/about-us" sx={{ ...linkStyles, ...getLinkStyles("/about-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaInfoCircle />
                <Text ml={2}>About Us</Text>
              </Box>
            </Link>
            <Divider />
            <Link as={NavLink} to="/signup" sx={{ ...linkStyles, ...getLinkStyles("/signup") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                <FaSignInAlt />
                <Text ml={2}>SME SignUp</Text>
              </Box>
            </Link>
          </>
        )}
      </Flex>

      <Flex direction="column" flex="1" overflow="auto" ml="150px">
        <SupplierLogo />
        <Box p={4}>
          {isAuthenticated && !loading && retailers.length > 0 && <RetailerNavigation />}
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            {isAuthenticated && (
              <>
                <Route
                  path="/myProfile"
                  element={
                    <>
                      <Helmet>
                        <title>MyProfile - Spearbi</title>
                        <meta name="description" content="Edit & manage your profile & subscription" />
                      </Helmet>
                      <MyProfile />
                    </>
                  }
                />
                <Route
                  path="/sales"
                  element={
                    <>
                      <Helmet>
                        <title>Sales - Spearbi</title>
                        <meta name="description" content="Analyze your sales data comprehensively with Spearbi's reporting tools." />
                      </Helmet>
                      <Sales />
                    </>
                  }
                />
                <Route
                  path="/strike"
                  element={
                    <>
                      <Helmet>
                        <title>Strike Rate - Spearbi</title>
                        <meta name="description" content="Evaluate and improve your strike rate with Spearbi's advanced analytics." />
                      </Helmet>
                      <StrikeRate />
                    </>
                  }
                />
                <Route
                  path="/ranging"
                  element={
                    <>
                      <Helmet>
                        <title>Ranging - Spearbi</title>
                        <meta name="description" content="Identify active articles in stores across selected retailers with Spearbi's reporting tools." />
                      </Helmet>
                      <Ranging />
                    </>
                  }
                />
                <Route
                  path="/margin"
                  element={
                    <>
                      <Helmet>
                        <title>Margin Report - Spearbi</title>
                        <meta name="description" content="Analyze your margin report comprehensively with Spearbi's reporting tools." />
                      </Helmet>
                      <Margin />
                    </>
                  }
                />
                <Route
                  path="/imports"
                  element={
                    <>
                      <Helmet>
                        <title>Imports - Spearbi</title>
                        <meta name="description" content="Manage and analyze your imports with Spearbi's robust reporting features." />
                      </Helmet>
                      <Imports />
                    </>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <>
                      <Helmet>
                        <title>Users - Spearbi</title>
                        <meta name="description" content="Manage user accounts and permissions within the Spearbi system." />
                      </Helmet>
                      <Users />
                    </>
                  }
                />
                <Route
                  path="/articles"
                  element={
                    <>
                      <Helmet>
                        <title>Articles - Spearbi</title>
                        <meta name="description" content="Manage supplier articles and permissions within the Spearbi system." />
                      </Helmet>
                      <Articles />
                    </>
                  }
                />
                <Route
                  path="/stores"
                  element={
                    <>
                      <Helmet>
                        <title>Stores - Spearbi</title>
                        <meta name="description" content="Manage retailer stores and permissions within the Spearbi system." />
                      </Helmet>
                      <Stores />
                    </>
                  }
                />
                <Route
                  path="/loginhistory"
                  element={
                    <>
                      <Helmet>
                        <title>Login History - Spearbi</title>
                        <meta name="description" content="Admin functionality to view login history within the Spearbi system." />
                      </Helmet>
                      <LoginHistory />
                    </>
                  }
                />
                <Route
                  path="/edi"
                  element={
                    <>
                      <Helmet>
                        <title>EDI - Spearbi</title>
                        <meta name="description" content="Manage Edi functionality for all Spear bi's selected retailers" />
                      </Helmet>
                      <Edi />
                    </>
                  }
                />
                <Route
                  path="/delete"
                  element={
                    <>
                      <Helmet>
                        <title>EDI - Spearbi</title>
                        <meta name="description" content="Delete & manage data within Spearbi's system" />
                      </Helmet>
                      <Delete />
                    </>
                  }
                />

                <Route path="/addUser" element={<AddUser />} />
                <Route path="/editPageAccess/:userid" element={<EditPageAccess />} />
                <Route path="/editSupplierAccess/:userid" element={<EditSupplierAccess />} />
                <Route path="/editRetailerAccess/:userid" element={<EditRetailerAccess />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/signin" replace />} />
            <Route path="/" element={<Signin />} />
          </Routes>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
